import { stringifyQueryString } from '@shein/common-function'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import bffFilterAdapter from './bffFilterAdapter.js'

class BffAdapter {
  constructor({
    data,
    pageType,
    requestType,
    query,
  }) {
    this.data = data || {}
    this.pageType = pageType
    this.requestType = requestType || ''
    this.query = query
    this.lang = this.data?.lang || (typeof window !== 'undefined' && gbCommonInfo.lang)
  }

  get #cccFeedbackInfo() {
    return this.data.bffCccFeedbackInfo || {}
  }

  get #bffProductsInfo() {
    return this.data.bffProductsInfo || {}
  }

  get #bffFilterInfo() {
    return this.data.bffFilterInfo || {}
  }

  get #listDiffParams() {
    const diffParams = this.#getListDiffParams()
    return {
      ...diffParams,
      isStorePage: diffParams.type === 'store', // 店铺
      isSearchPage: diffParams.type === 'search', // 搜索结果页
      isRealPage: diffParams.type === 'entity', // 真实分类页
      isSelectPage: diffParams.type === 'selection', // 选品页
      isPickPage: diffParams.type === 'pick' // 信息流落地页
    }
  }

  get #bffSearchBannerCardInfo() {
    return this.data.bffSearchBannerCardInfo || {}
  }

  // 字段适配（bff字段 -> 原来的字段）
  adaptData() {
    this.#adaptCatInfo()
    this.#adaptGoods()
    this.#adaptGoodsOtherParameters()
    this.#adaptCccConfig()
    this.#adaptCardConfig()
    this.#adaptFilters()
    this.#adpatSearchResultInterfaceCode()
    this.#adaptSearchKeywords()
    this.#adaptBreadcrumbs()
    this.#adaptSearchBannerCardInfo()
    return this.getData()
  }

  getData() {
    if (!(this.#hasBffProductsInfo() || this.#hasBffFilterInfo())) return null
    // 剔除多余的字段
    const removeKeys = ['bffCccFeedbackInfo', 'bffFilterInfo', 'bffProductsInfo']
    return Object.keys(this.data).reduce((data, key) => {
      if (removeKeys.includes(key)) return data
      data[key] = this.data[key]
      return data
    }, {})
  }

  // 构造searchResultInterfaceCode
  #adpatSearchResultInterfaceCode() {
    if (!this.#hasBffProductsInfo()) return
    const buriedPoint = this.#bffProductsInfo.buriedPoint
    if (buriedPoint) {
      this.data.searchResultInterfaceCode = buriedPoint
      return 
    }
    if (this.#bffProductsInfo.code === '0') {
      this.data.searchResultInterfaceCode = 1
      return
    }
    this.data.searchResultInterfaceCode = '4_1001'
  }

  #hasBffProductsInfo() {
    return Object.keys(this.#bffProductsInfo)?.length
  }

  #hasBffFilterInfo() {
    return Object.keys(this.#bffFilterInfo)?.length
  }

  #getListDiffParams() {
    // 真实分类页对应的真实分类id
    const productBffCateId = this.#bffProductsInfo?.cateId
    // 搜索结果匹配的cateid
    const searchCateId = this.#bffProductsInfo?.cate_ids?.[0]
    const search = this.pageType === 'search'
    if (search) {
      const keywords = this.#bffProductsInfo?.origin_words || this.query?.keywords || ''
      return {
        type: 'search',
        pageName: 'page_search',
        cat_id: productBffCateId || searchCateId || '',
        searchWord: keywords,
        keywords: keywords,
      }
    }
    // 店铺
    if(this.pageType === 'store'){
      const keywords = this.#bffProductsInfo?.origin_words || this.query?.keywords || ''
      return {
        type: 'store',
        sub_type: this.data.cat_info?.sub_type,
        pageName: this.data.cat_info?.pageName,
        select_id: this.data.cat_info?.select_id || this.data.cat_info?.routeId,
        store_code: this.data.cat_info?.store_code,
        searchWord: keywords,
        keywords: keywords,
        result_type: this.data.cat_info?.result_type,
      }
    }
    // 卖点列表页
    if (this.pageType === 'selling') {
      return {
        type: 'selling',
        pageName: 'page_selling_point',
        tag_val_id: this.data.cat_info?.tag_val_id,
        pageStyleKey: 'page_selling_point_list',
      }
    }
    // daily_new落地页
    if (this.pageType === 'daily_new') {
      return {
        type: 'daily_new',
        pageName: 'page_daily_new',
      }
    }

    // // 真实列表页
    const isRealList = this.pageType === 'entity'
    if (isRealList) {
      return {
        type: 'entity',
        pageName: 'page_real_class',
        entity_id: productBffCateId,
        cat_id: productBffCateId || searchCateId || ''
      }
    }
    // // 选品列表页
    const isSelectList = this.pageType === 'selection'
    // 选品页对应的选品真实分类id
    const filterBffCateId = this.#bffFilterInfo?.cateId
    // 选品页对应的selectId
    const filterBffSelectId = this.#bffProductsInfo?.category_id
    if (isSelectList) {
      return {
        type: 'selection',
        pageName: 'page_select_class',
        select_id: filterBffSelectId,
        selection_entity_id: filterBffCateId || ''
      }
    }

    // shein picks
    const isSheinPicks = this.pageType === 'picks'
    if (isSheinPicks) {
      return {
        type: 'picks',
        pageName: 'page_goods_group',
        pageStyleKey: 'shein_picks',
        picksType: this.query.picksType,
        picks_id: this.query.picks_id,
        sub_type: this.query.sub_type,
        cat_id: this.query.cat_id,
        entity_id: this.query.entity_id,
        select_id: this.query.select_id,
        navId: this.query.navId,
        cacheKey: this.query.cacheKey,
        picksTitle: this.data.cat_info.picksTitle,
        picksLink: this.data.picksLink || this.data.cat_info.picksLink,
        _type: 'sheinPicks',
      }
    }

    return {}
  }

  // 成人用品
  #adaptAdultProduct(isSearchPage) {
    // 第二页不请求筛选数据，会影响成人弹窗状态判断，筛选后的内容是有可能要展示成人弹窗的
    // pc与pwa不同，会把page带到url上
    // 如果是翻页，说明是用户点击的，页面一定是可展示商品的，不展示弹窗
    const hideDialog = this.query && this.query.requestType === 'pageChange' && this.query.page >= 1
    if (hideDialog) {
      return false
    }
    const { isAdultValid } = this.data.cat_info || {}
    const show_adult_tip = isSearchPage
      ? this.#bffProductsInfo?.show_adult_tip
      : this.data?.bffFilterInfo?.show_adult_tip ||
        this.data?.bffFilterInfo?.showAdultTip
    return isAdultValid && show_adult_tip && show_adult_tip !== '0'
  }

  // 构造cat_info
  #adaptCatInfo() {
    /**
     * 一些待做的值，此次搜索融合先不做
     * entity_id catInfo.entity_id || catInfo.select_id
     * select_id catInfo.entity_id || catInfo.select_id return this.catInfo?.select_id
     * showFeedbackRec: this.catInfo.showFeedbackRec !== 0
     *
     * */

    if (!(this.#hasBffProductsInfo() || this.#hasBffFilterInfo())) return

    const isDefaultChild =
      this.data?.bffFilterInfo?.tagChild?.defaultChild?.child_id // 默认kids流程

    const listParams = this.#listDiffParams
    const cat_info = {
      switchBff: true,
      ...listParams,
      useBffFilters: this.query.useBffFilters,
      useBffProducts: this.query.useBffProducts,
      suggestedSaleTypeBff: this.#bffProductsInfo?.suggestedSaleType || '',
      hasAdultProduct: this.#adaptAdultProduct(listParams.isSearchPage),
      requestType: this.requestType,
      page: this.query.page,
      limit: this.query.limit,
      search_type: this.query.search_type,
      tsp_ids: this.query.tsp_ids,
      tag_ids:
        this.query.tag_ids || isDefaultChild ? this.data.cat_info.tag_ids : '', // 有默认的tagChild时，tag_ids取值
      min_price: this.query.min_price,
      max_price: this.query.max_price,
      sort: this.query.sort,
      attr_values: this.query.attr_values,
      attr_ids: this.query.attr_ids,
      brand_ids: this.query.brand_ids,
      attr_node_ids: this.query.attr_node_ids,
      child_cat_id: this.query.child_cat_id,
      daily: this.query.daily,
      ici: this.query.ici,
      sil_type: this.query.sil_type,
      ShowTip: this.query.ShowTip,
      child_cat_id_void: this.query.search_id ? true : false,
      search_redir: this.query.search_redir,
      filterWords: this.query.filterWords,
      filterAttrIds: this.query.filterAttrIds, // 待确认是否使用
      store_code: this.query.store_code,
      intent_channel_id: this.query.intent_channel_id,
      url_from: this.query.url_from,
      src_identifier: this.query.src_identifier,
      src_module: this.query.src_module,
      src_tab_page_id: this.query.src_tab_page_id,
      userpath: this.query.userpath,
      is_from_list_feeds: this.query.is_from_list_feeds,
      result_Ioc: this.query.result_Ioc,
      list_cat_name: this.query.list_cat_name,
      lockMall: this.query.lockMall,
      mallCode: this.query.mallCode,
      styleType: this.query.styleType,
      pageListType: this.#bffProductsInfo?.context?.page_list_type || 4,
      sortScene: this.#bffProductsInfo?.sortPoskey,

      show_daily: this.query.show_daily,
      kid_id: this.query.kid_id || isDefaultChild ? this.data.cat_info.kid_id : '',
      showAllCategory: '1',
      fromPageType: this.query.fromPageType,
      entranceType: this.query.entranceType,
      pageFrom: this.query.pageFrom,
      selectAttributeGroup: this.query.selectAttributeGroup || '', // 选中的属性组合
    }
    this.data.cat_info = Object.keys(cat_info).reduce((catInfo, key) => {
      if (cat_info[key] === undefined || cat_info[key] === null) return catInfo
      catInfo[key] = cat_info[key]
      return catInfo
    }, {})
  }

  // 构造searchKeywords - S
  #checkRedirection({ action, page_type, page_id, url }) {
    const { PICKS_LINKS, langPath } = gbCommonInfo || {}
    if (page_type == 40) {
      // 付费会员没有url地址， 提前判断
      url = '/user/prime'
    }
    // cccx url字段只会返回当前配置的page_id，用这个来进行匹配....
    if (page_type == 41) {
      let picksTable = PICKS_LINKS || {}
      let pageId = page_id?.split('/')?.[0] || url
      url = picksTable[pageId] ? `/${picksTable[pageId]}.html` : ''
    }
    // 列表链接分类说明参见： wiki.pageId=192285255
    const page_types = [
      1, // 'search'搜索结果页
      2, // 'real'真实分类页
      // 3, // '-废弃virtual-'
      4, // 'webLink'
      5, // 'itemPicking' 选品分类
      6, // 'sku' 商详
      7, // 'article' 文章
      8, // 'activity' 专题
      9, // 'game' 游戏
      10, // 'lookBook' 套装lookbook
      // 11, // app 专用
      12, // 'store' 第三方商铺
      39, // '新专题'
      40, // '付费会员'
      26, // Category
      16, // DailyNew
      37, // Flashsale
      25, //优惠券中心
      41 // sheinpick
    ]
    if (action !== 'redirect' || !url || !page_types.includes(+page_type))
      return null

    // 拼接完整路径
    url = /^\/[^\/]/.test(url) ? `${langPath}${url}` : `//${url}`

    const mall_code = {}
    if (page_type == 2 || page_type == 5 || page_type == 41) {
      const mallCode = page_id?.split('/')?.[1]
      mallCode && Object.assign(mall_code, { mallCode })
    }
    const queryObj = {
      search_redir: 1,
      search_words: this.data?.cat_info?.searchWord,
      ici: this.data?.cat_info?.ici?.replace('`d0`', '`d1`'),
      src_identifier: this.data?.cat_info?.src_identifier || '',
      src_tab_page_id: this.data?.cat_info?.src_tab_page_id || '',
      src_module: this.data?.cat_info?.src_module || '',
      ...mall_code
    }

    if (page_type == 12) {
      url = `${langPath}/store/home`
      queryObj.store_code = page_id
    }
    return `${url}${url.includes('?') ? '&' : '?'}${stringifyQueryString({
      queryObj
    })}` // 生成最终的
  }

  #adaptSearchKeywords() {
    if (!this.#hasBffProductsInfo() || !this.data.cat_info?.isSearchPage) return
    const { searchWord } = this.data?.cat_info || {}
    const { is_suggest_res, suggest_words, origin_num, double_lang_correct } = this.#bffProductsInfo
    const escapedSearchWord = searchWord
      ?.replace('<', '&lt;')
      .replace('>', '&gt;')
    this.data.searchKeywords = {
      keywords: escapedSearchWord,
      redirectUrl: this.#checkRedirection(this.#bffProductsInfo), // TODO: 先暂时自己生产直达链接的逻辑，后面转到bff
      origin_words: escapedSearchWord,
      origin_total: origin_num,
      is_suggest_res: Number(is_suggest_res) || 0,
      suggest_words,
      double_lang_correct,
      suggest_total: suggest_words ? this.data.sum : 0 // sum是要adaptGoodsOtherParameters执行完后才有
    }
  }
  // 构造searchKeywords - E

  // 构造goods
  #adaptGoods() {
    if (!this.#hasBffProductsInfo()) return
    const isSearchPage = this.data.cat_info?.type === 'search'  // 是否搜索结果页
    const storeSearch = isSearchPage && this.data.cat_info?.search_type === 'store'   // 店铺搜索页
    const brandSearch = isSearchPage && this.data.cat_info?.search_type === 'brand'     // 品牌搜索页
    const cccFeedback = this.#cccFeedbackInfo?.extendConfigDetail?.find(
      item => {
        const dataPageStyleKey = this.data.cat_info?.pageStyleKey || this.data.cat_info?.pageName
        return (
          item?.pageStyleKey === dataPageStyleKey &&
          item.sceneKey === 'TWO_IN_A_ROW'
        )
      }
    )
    const getItemInfo = (v) => {
      if (!this.lang) {
        throw new Error('lang is required')
      }
      return goodsTransformation(v, {
        suggestedSaleType: this.#bffProductsInfo?.suggestedSaleType || '',
        language: this.data.language ?? {},
        lang: this.lang,
        isPaid: !!this.data.sheinClubInfo?.isPaid
      })
    }
    this.data.goods = this.#bffProductsInfo.products?.map?.(v => {
      if (cccFeedback) {
        v.cccFeedback = cccFeedback
      }
      const goodsInfo = getItemInfo(v)
      // 品牌搜索&店铺搜索。中间层返回了，但需要屏蔽的功能
      if (brandSearch || storeSearch) {
        if (goodsInfo.showAddButtonLabelStyle) {
          goodsInfo.showAddButtonLabelStyle = ''
        }
      }
      return goodsInfo
    }) || []
  }

  // 构造cardConfig(商卡配置)
  #adaptCardConfig() {
    if (!this.#hasBffProductsInfo() || !this.data.cardConfig) return
    // 标题相关配置
    const goodsNameLineLimit = +this.#bffProductsInfo?.listStyle?.twoColumnStyle?.goodsNameLineLimit
    if ([1, 2].includes(goodsNameLineLimit)) {
      this.data.cardConfig.showTitle = true
      this.data.cardConfig.showGoodsNameTwoLine = goodsNameLineLimit === 2
    }
    // 加车按钮是否显示
    const bffGoodsInfo = this.#bffProductsInfo?.products?.[0] ?? {}
    if (bffGoodsInfo?.productMaterial?.showAddButtonLabel) {
      this.data.cardConfig.showAddBagBtn =
        bffGoodsInfo.productMaterial.showAddButtonLabel === '1'
    }
    // 临时兜底 如果没有返回商品 无结果 则把加车按钮显示出来
    const { page, type } = this.data?.cat_info ?? {}
    if (
      type === 'search' &&
      page === 1 &&
      !bffGoodsInfo?.productMaterial &&
      this.data?.cardConfig
    ) {
      this.data.cardConfig.showAddBagBtn = true
    }
    // urlQuery相关配置
    if (!this.data.cardConfig.urlQuery) this.data.cardConfig.urlQuery = {}
    this.data.cardConfig.urlQuery.pageListType = this.data.cat_info.pageListType
  }

  // 构造筛选（图文，分类/属性/tsp筛选，标签云）
  #adaptFilters() {
    if (!this.#hasBffFilterInfo()) return

    bffFilterAdapter(this.data, this.#bffFilterInfo)
  }

  #adaptBreadcrumbs() {
    if (this.requestType === 'firstLoad' && this.#hasBffProductsInfo()) {
      this.data.currentCat = {
        cat_id: this.#bffProductsInfo.cateId || '',
        cat_name: this.#bffProductsInfo.category_name || '',
        parent_id: this.#bffProductsInfo.parentId || '',
        brande_detail: this.#bffProductsInfo.brand_detail || '',
        select_name_multi: this.#bffProductsInfo.category_name || '',
        valName: this.#bffProductsInfo.category_name || '',
      }
    }
  }

  #adaptGoodsOtherParameters() {
    if (!this.#hasBffProductsInfo()) return
    // 下游返回的request_ext透传
    this.data.request_ext = this.#bffProductsInfo?.request_ext ?? {}
    this.data.cateIds = this.#bffProductsInfo.cateIds || []
    // 商品总数
    this.data.sum = this.#bffProductsInfo.num
    this.data.sumForPage = this.#bffProductsInfo.numForPage
    // 中间层过滤的商品id
    this.data.frontFilterGoods = this.#bffProductsInfo.frontFilterGoods
    // 端智能透传数据
    this.data.data_c = this.#bffProductsInfo.data_c
  }

  #adaptSearchBannerCardInfo() {
    // bff key -> web key
    const KEY_MAP = new Map([
      ['cccStyleInfo', 'searchCCCCard'],
      ['storeCardInfo', 'searchStoreCard'], // 旧的店铺卡片获取字段
      ['storeCardList', 'storeCardList'], // 新的店铺卡片获取字段，03.25支持多个店铺卡片
      // ['trendInfo', 'searchTrendCard']
    ])
    const bffData = this.#bffSearchBannerCardInfo || {}


    if (!Object.keys(bffData).length) return
    const result = {}
    this.data.searchBannerCard = result
    Object.keys(bffData).forEach(key => {
      const bffInfo = bffData[key]
      if (bffInfo && typeof bffInfo === 'object') {
        bffInfo.isBff = true
        bffInfo.lang = this.lang
        bffInfo.suggestedSaleType = bffData.suggestedSaleType || ''
        result[KEY_MAP.get(key)] = bffInfo
      }
    })
    this.data.searchBannerCard = result
  }

  // 创建实时反馈以及榜单插坑ccc配置 需要比筛选adapter更早执行
  #adaptCccConfig() {
    if (typeof window === 'undefined') return false
  
    const useBffCccConfig = isSwitchBffApiVersion(['ccc_config'])?.ccc_config
    if (useBffCccConfig) {
      this.data.cccConfig = {
        feedBackStyle: this.#bffProductsInfo?.listStyle?.feedBackStyle ?? {},
        rankListModuleSetting: this.#bffProductsInfo?.listStyle?.rankListModuleSetting ?? {},
        productTypeSortConfig: [],
        tagCloudSlotConfig: []
      }
    }
  }
}

export default BffAdapter
